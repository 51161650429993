import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router} from "@angular/router";
import {OwlOptions} from "ngx-owl-carousel-o";

@Component({
    selector: 'tekr-home-de',
    templateUrl: './home-de.component.html',
    styleUrls: ['./home-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeDeComponent implements OnInit {
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        autoplay: true,
        dots: true,
        navSpeed: 1500,
        navText: ['', '', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            740: {
                items: 1
            },
            940: {
                items: 1
            }
        },
        nav: false
    }

    constructor(private router: Router, private viewportScroller: ViewportScroller) {
    }

    ngOnInit(): void {
        localStorage.setItem('refreshed', 'false')

        if (localStorage.getItem('lang') === 'en') {
            this.router.navigate(['/'])
        }
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
