<section class="text-gray-600 bg-white dark:bg-gray-900 md:bg-work-purple md:bg-top body-font">
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:flex-grow md:w-6/12 lg:w-4/12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <!--<h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900 max-w-2xl">
                We improve software productivity through artificial intelligence
            </h1>-->
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
                Pädagogische KI zur Neudefinition Ihres Klassenzimmers
            </h1>
            <p class="mb-8 leading-relaxed max-w-prose">
                Lukaz ist eine anpassbare, sichere Plattform, die Pädagogen dabei hilft, den Schülern schrittweise
                KI-Technologie vorzustellen und so Engagement und Innovation in einer integrativen Umgebung zu fördern.
            </p>
            <div class="container mx-auto flex flex-wrap flex-col md:flex-row gap-4">
                <div class="">
                    <a [routerLink]="'teaching'"
                       id="home-lm-org"
                       class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-indigo-500 rounded text-lg">
                        Mehr erfahren
                    </a>
                </div>
                <div class="">
                    <a href="https://lukaz.ai" target="_blank"
                       id="home-cta-org"
                       class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-500 py-2 px-6 focus:outline-none hover:bg-indigo-300 rounded text-lg">
                        KI-Tafel erstellen
                    </a>
                </div>
            </div>

        </div>
        <div class="w-full md:w-6/12 lg:w-8/12 items-center text-center md:ml-12 mb-8">
            <img src="assets/img/screenshots/teaching-3.png" alt="Lukaz Board" class="hidden lg:block mx-auto"/>
            <img src="assets/img/screenshots/teaching-3-mobile.png" alt="Lukaz Board" class="lg:hidden mx-auto"/>
        </div>
    </div>

</section>

<section class="relative text-gray-100 bg-indigo-500 body-font md:bg-connections-white-inv md:bg-top">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-indigo-500 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
        <div
            class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">

        </div>
        <div
            class="lg:flex-grow md:w-1/2 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 class="text-gray-100 dark:text-gray-100 title-font sm:text-4xl text-3xl mb-4 font-medium max-w-md">
                Über 600 deutsche Schulen benutzen Lukaz
            </h1>
            <p class="text-gray-100 dark:text-gray-100 mb-8 leading-relaxed max-w-prose">
                Lukaz unterstützt Lehrer dabei, ihre Arbeitsbelastung im Klassenzimmer zu reduzieren, indem es eine sichere,
                KI-gesteuerte Plattform bereitstellt, die gleichermaßen auf Lehrer und Schüler zugeschnitten ist.
            </p>
            <div class="flex w-full md:justify-start justify-center items-end md:mb-8">
                <a [routerLink]="'teaching'"
                   id="home-lm-dev"
                   class="inline-flex text-gray-100 dark:text-gray-100 bg-indigo-300 dark:bg-indigo-300 py-2 px-6 focus:outline-none hover:bg-gray-100 dark:hover:bg-gray-100 hover:text-indigo-500 dark:hover:text-indigo-500 rounded text-lg">
                    Mehr erfahren
                </a>
                <a href="https://lukaz.ai" target="_blank"
                   id="home-cta-dev"
                   class="ml-2 md:ml-4 inline-flex text-indigo-500 dark:text-indigo-500 bg-gray-100 dark:bg-gray-100 py-2 px-6 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 hover:text-gray-100 dark:hover:text-gray-100 rounded text-lg">
                    Registrieren
                </a>
            </div>
        </div>
    </div>

</section>

<section class="relative text-gray-600 bg-gray-100 md:bg-top body-font" id="press">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 pt-24 pb-12 mx-auto">
        <div class="flex flex-col w-full">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Highlight der Didacta 2024</h1>

            <p class="mb-8 leading-relaxed max-w-prose">
                Lukaz wurde stolz auf der Didacta 2024 in Köln präsentiert, wo der deutsche öffentlich-rechtliche Fernsehsender
                <a class="underline" href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                    RTL unsere innovative KI-Lösung für Schulen vorstellte</a>.<br/><br/>
                CEO Maximilian Däweritz zeigte, wie Lukaz die Zukunft der Bildung gestaltet.
            </p>
        </div>

        <div class="max-w-prose mb-12">
            <a
                href="https://www.rtl.de/cms/messe-didacta-setzt-kuenstliche-intelligenz-in-schulen-auf-die-agenda-5077601.html" target="_blank">
                <img src="assets/img/press/Didacta-2024.png" alt="Didacta 2024" class="rounded-xl">
            </a>
        </div>

        <!--<div class="w-full mb-24">
            <video
                loop
                muted
                controls
                class="rounded-2xl"
            >
                <source
                    src="assets/img/press/Didacta-2024.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>-->

    </div>
</section>

<!--
<section class="relative flex items-center justify-center h-screen overflow-hidden">
    &lt;!&ndash;<div
        class="relative z-30 p-5 text-2xl text-white bg-purple-300 bg-opacity-50 rounded-xl"
    >
        Welcome to my site!
    </div>&ndash;&gt;
    <video
        autoplay
        loop
        muted
        controls
        class="absolute z-10 w-auto min-w-full min-h-full max-w-none"
    >
        <source
            src="assets/img/press/Didacta-2024.mp4"
            type="video/mp4"
        />
        Your browser does not support the video tag.
    </video>
</section>-->

<tekr-contact-section-de></tekr-contact-section-de>

<section class="relative text-gray-600 bg-gray-100 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-100 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container mx-auto flex px-5 pt-24 md:flex-row flex-col items-center">
        <div class="flex flex-col w-full mb-10 text-center md:text-left">
            <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
                In Partnerschaft mit Branchenführern
            </h1>
            <p class="max-w-prose mb-8">
                Unsere Partnerschaften mit globalen und regionalen Innovatoren fördern den Erfolg branchenübergreifend.
            </p>
            <!--<h2 class="mx-auto max-w-prose sm:text-2xl text-xl font-medium title-font mb-4 text-gray-900">
                Some of our partners:
            </h2>-->
        </div>
    </div>

    <div class="container mx-auto flex px-5 pb-24 md:flex-row flex-col items-center">
        <div class="flex flex-col md:flex-row w-full mb-10 gap-8">
            <div class="text-center md:text-left">
                <img src="assets/img/partners/terra.png" alt="Terra" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Wortmann AG
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/microsoft.png" alt="Microsoft" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Microsoft Corporation
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/eduplaces.png" alt="Eduplaces" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    Eduplaces GmbH
                </h2>
            </div>
            <div class="text-center md:text-left">
                <img src="assets/img/partners/iserv.png" alt="iServ" class="mx-auto rounded-xl shadow-xl"/>
                <h2 class="leading-relaxed mt-4 italic">
                    iServ GmbH
                </h2>
            </div>
        </div>
    </div>
</section>

<section class="relative bg-gray-900 md:bg-top body-font text-center" id="testimonials">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>

    <div class="container px-5 py-24 mx-auto text-center">
        <div class="max-w-prose text-center mx-auto mb-12">
            <owl-carousel-o [options]="customOptions">
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        Die einfache Integration der innovativen KI-Lösungen von Tekr in unser Softwarepaket in
                        Zusammenarbeit mit Microsoft zeigt ihren Wert.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">ANDREAS BARKOWSKI</h2>
                    <p class="text-gray-400 mb-8">Vertriebsleiter - Wortmann AG</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        Tekr hat uns beim Aufbau des Kerns unserer KI-gestützten Software geholfen,
                        die das Kundenerlebnis für Giganten wie Audi, P&G und Coop verbessert.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">MARC FUNK</h2>
                    <p class="text-gray-400 mb-8">CEO - Frontnow GmbH</p>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="inline-block w-8 h-8 text-gray-400 mb-8" viewBox="0 0 975.036 975.036">
                            <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                        </svg>
                    </div>
                    <p class="leading-relaxed text-lg text-gray-200">
                        Dank großartiger Innovationen, von denen einige vom hochqualifizierten Team von Tekr
                        hervorgebracht wurden, ist MyDose heute eine erfolgreiche Wellness-App.
                    </p>
                    <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-8 mb-6"></span>
                    <h2 class="text-gray-100 font-medium title-font tracking-wider text-sm">GUSTAVO MARION</h2>
                    <p class="text-gray-400 mb-8">CEO - MyDose LLC</p>
                </ng-template>
            </owl-carousel-o>

        </div>
    </div>
</section>

<tekr-pipeline-section-de></tekr-pipeline-section-de>

