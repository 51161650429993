import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Meta} from '@angular/platform-browser';
import {Router} from "@angular/router";

@Component({
    selector: 'tekr-teaching',
    templateUrl: './teaching-de.component.html',
    styleUrls: ['./teaching-de.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TeachingDeComponent implements OnInit {

    constructor(private router: Router, private viewportScroller: ViewportScroller, private metaTagUseCase: Meta,
                private ref: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        localStorage.setItem('refreshed', 'false')

        if (localStorage.getItem('lang') === 'de') {
            this.router.navigate(['de/teaching'])
        }
        /*this.metaTagFeature.updateTag(
            {
                name: 'keywords',
                content: 'OOOii',
            },
            'keywords'
        );*/
        this.ref.markForCheck()
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
        const position = this.viewportScroller.getScrollPosition();
        position[1] = position[1] - 50;
        this.viewportScroller.scrollToPosition(position);
    }
}
