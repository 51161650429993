<section class="relative text-gray-600 bg-white dark:bg-gray-900 body-font">
    <div
        class="bottom-auto top-3 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style="height: 80px; transform: translateZ(0px);"
    >
        <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
        >
            <polygon
                class="text-white dark:text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
            ></polygon>
        </svg>
    </div>
    <div class="container px-5 py-24 mx-auto">
        <div class="flex flex-col text-center w-full mb-20">
            <!--            <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">FOR ANY KIND OF SOFTWARE</h2>-->
            <div class="max-w-prose mx-auto">
                <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4 max-w-md">
                    Generative KI – 100 % lokal, 100 % sicher
                </h1>
            </div>
            <p class="mx-auto max-w-prose">
                Lukaz bietet beispiellose Flexibilität durch die Anpassung an die Infrastruktur Ihrer Schule und stellt sicher,
                dass Daten stets sicher gespeichert und verarbeitet werden. Vollständige DSGVO-Konformität,
                Barrierefreiheitsstufe WCAG 2.1 AA und laufende ISO 27001-Zertifizierung.
            </p>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Lukaz Server</h2>
                <p class="leading-relaxed text-base">
                    Installieren Sie Lukaz auf Ihrer bestehenden Serverinfrastruktur. Die von LLMs verarbeiteten Daten
                    verbleiben vollständig auf den Servern Ihrer Schule.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Lukaz Client</h2>
                <p class="leading-relaxed text-base">
                    Laufen Sie Lukaz auf jedem Desktop oder Laptop (Windows, Linux oder MacOS).
                    Greifen Sie auf alle lokalen LLMs zu, auch ohne Internetverbindung.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                </svg>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div
                class="sm:w-32 sm:h-32 h-20 w-20 sm:mr-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round"
                     stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" />
                </svg>
            </div>
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Lukaz Custom</h2>
                <p class="leading-relaxed text-base">
                    Keine lokale GPU? Kein Problem. Nutzen Sie unsere vertrauenswürdigen GPU-Server, die auf Terra Cloud
                    gehostet werden, zusammen mit den Client- und Serverlösungen von Lukaz.
                </p>
            </div>
        </div>
        <div class="flex items-center lg:w-3/5 mx-auto pb-10 mb-10 border-gray-300 sm:flex-row flex-col">
            <div class="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                <h2 class="text-gray-900 text-lg title-font font-medium mb-2">Lukaz Cloud</h2>
                <p class="leading-relaxed text-base">
                    Falls Ihrer Schule ein Server fehlt, gewährleistet Lukaz Cloud die Datensicherheit mit Servern der
                    Terra Cloud GmbH, gesichert durch die Securepoint GmbH.
                </p>
            </div>
            <div
                class="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-500 text-gray-100 dark:text-gray-100 flex-shrink-0">
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     class="sm:w-16 sm:h-16 w-10 h-10" viewBox="0 0 24 24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 15a4 4 0 004 4h9a5 5 0 10-.1-9.999 5.002 5.002 0 10-9.78 2.096A4.001 4.001 0 003 15z" />
                </svg>
            </div>
        </div>
        <button
            (click)="goToLink('https://lukaz.ai')"
            id="pipeline-section-cta"
            class="click-tag flex flex-auto mx-auto text-center mt-15 mb-10 text-gray-100 dark:text-gray-100 dark:text-gray-100 bg-indigo-500 border-0 py-2 px-8 focus:outline-none hover:bg-indigo-300 dark:hover:bg-indigo-300 rounded text-lg">
            Benutzerkonto erstellen
        </button>
    </div>
</section>
